<template>
  <div class="text-center">
    <template v-if="step != 'register'">
      <h5 class="text-center">Venha curtir com a Atletic.Me</h5>
      <h6 class="text--disabled text-center font-weight-medium mb-10">
        {{
          !cacheUser
            ? `Informe seu WhatsApp para começar`
            : `É bom te ver de volta ${
                cacheUser.name.split(" ")[0]
              }, informe sua senha para continuar`
        }}
      </h6>

      <div v-show="!cacheUser">
        <phone-input
          :phone="whatsapp"
          @update:phone="(e) => (whatsapp = e)"
          :ddi="ddi"
          @update:ddi="(e) => (ddi = e)"
          @valid="(v) => (valid = v)"
          dense
          outlined
          :disabled="loading || loadingWebAuthn"
        />
      </div>
      <div class="d-flex text-left align-center mb-3" v-if="!!cacheUser">
        <base-avatar
          :size="50"
          :src="cacheUser.photo"
          :seed="cacheUser.id"
          class="mr-3"
        />
        <div class="flex-grow-1">
          <h6 class="font-weight-medium mb-0">{{ cacheUser.name }}</h6>
          <p class="text--disabled mb-0">{{ cacheUser.phone }}</p>
        </div>
        <v-btn icon @click="cCacheUser" :disabled="loading || loadingWebAuthn">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <v-text-field
        v-on:keyup.enter="processRecaptcha"
        v-show="step == 'login'"
        name="input-10-2"
        label="Senha"
        type="password"
        ref="password"
        :disabled="loading || loadingWebAuthn"
        v-model="password"
        outlined
        dense
      ></v-text-field>
      <v-checkbox
        v-if="!inPwa"
        v-show="step == 'login'"
        :disabled="loading || loadingWebAuthn"
        label="Mantenha-me conectado"
        v-model="rememberMe"
        dense
        class="mt-0 pt-0"
      ></v-checkbox>
      <v-fade-transition hide-on-leave>
        <v-alert
          v-show="error"
          border="left"
          class="text-left"
          text
          icon="mdi-alert-circle-outline"
          type="error"
        >
          {{ error }}
        </v-alert>
      </v-fade-transition>

      <v-row class="px-3">
        <v-col
          cols="auto"
          class="pa-0 pr-2"
          v-if="authenticators.includes('WEBAUTHN')"
        >
          <v-btn
            color="primary"
            icon
            :disabled="!isValid || loading"
            :loading="loadingWebAuthn"
            @click="recaptchaWebAuthn"
          >
            <v-icon>mdi-shield-key</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="" class="pa-0">
          <v-btn
            :disabled="!isValid || loadingWebAuthn"
            color="primary"
            block
            :loading="loading"
            @click="processRecaptcha"
          >
            Continuar
          </v-btn>
        </v-col>
        <v-col cols="12" class="pa-0">
          <v-btn
            v-show="step == 'login'"
            small
            text
            @click="forgotPassword"
            class="mt-3"
          >
            Esqueceu a senha?
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <h5>Continue pelo Whatsapp</h5>
      <h6 class="text--disabled font-weight-medium mb-10">
        Enviamos um link no seu WhatsApp para finalizar o cadastro
      </h6>
      <v-btn
        color="secondary"
        small
        class="mt-4"
        block
        @click="step = 'identify'"
      >
        Voltar
      </v-btn>
    </template>
    <re-captcha
      ref="recaptcha"
      @success="process"
      @error="recaptchaError"
      @close="recaptchaError"
    />
  </div>
</template>

<script>
import ReCaptcha from "@/components/global/ReCaptcha.vue";
import AUTH from "@/services/auth";
import { mapActions, mapGetters } from "vuex";
import { startAuthentication } from "@simplewebauthn/browser";
import PhoneInput from "./PhoneInput.vue";

export default {
  components: { ReCaptcha, PhoneInput },
  name: "SignUp",
  metaInfo: {
    title: "Acessar conta",
  },
  data() {
    return {
      valid: false,
      ddi: 55,
      whatsapp: "",
      password: "",
      rememberMe: false,

      step: "identify",
      loading: false,
      loadingWebAuthn: false,
      pageLoading: true,
      error: false,
      authenticators: [],
      nextStep: null,
    };
  },
  methods: {
    ...mapActions("auth", ["setLogin", "clearCacheUser"]),
    recaptchaWebAuthn() {
      this.loadingWebAuthn = true;
      this.nextStep = "webauthn";
      this.$refs.recaptcha.execute();
    },
    processRecaptcha() {
      this.loading = true;
      this.error = false;
      this.$refs.recaptcha.execute();
    },
    async process(recaptcha) {
      if (this.nextStep) {
        let ns = this.nextStep;
        this.nextStep = null;
        return this[ns](recaptcha);
      }

      try {
        this.loading = true;

        if (this.step === "identify") {
          var response = await AUTH.login({
            ddi: this.ddi,
            phone: this.whatsapp,
            redirect:
              this.$route.query.redirect || this.$route.path !== "/auth"
                ? this.$route.path
                : undefined,
            recaptcha,
          });
          if (response.user) {
            // Usuário possui conta
            this.step = "login";
            this.$nextTick(() => {
              this.$refs.password.focus();
            });
            this.authenticators = response.authenticators;
          } else this.step = "register";
        } else if (this.step === "login") {
          if (!this.password) throw new Error("Senha é obrigatória");
          var response = await AUTH.login({
            ddi: this.ddi,
            phone: this.whatsapp,
            password: this.password,
            rememberMe: this.rememberMe || this.inPwa,
            recaptcha,
          });
          await this.setLogin({
            ...response,
            authenticators: this.authenticators,
          });
          this.$emit("success");
          if (this.redirect) {
            let to = this.$route.query.redirect || "/app";
            this.$router.push(to);
          }
        }
      } catch (e) {
        this.error = e.message;
      } finally {
        this.loading = false;
      }
    },
    async webauthn(recaptcha) {
      try {
        this.loadingWebAuthn = true;
        const { options } = await AUTH.webauthn.start(
          { phone: this.whatsapp, ddi: this.ddi },
          recaptcha
        );
        const credential = await startAuthentication(options);
        var response = await AUTH.webauthn.login({
          phone: this.whatsapp,
          ddi: this.ddi,
          rememberMe: this.rememberMe || this.inPwa,
          credential,
        });
        await this.setLogin({
          ...response,
          authenticators: this.authenticators,
        });
        this.$emit("success");
        if (this.redirect) {
          let to = this.$route.query.redirect || "/app";
          this.$router.push(to);
        }
      } catch (e) {
        this.error = e.message;
      } finally {
        this.loadingWebAuthn = false;
      }
    },
    forgotPassword() {
      this.$router.push({
        name: "forgot-password",
        query: { phone: this.whatsapp, ddi: this.ddi },
      });
    },
    recaptchaError(error) {
      this.loading = false;
      this.error = error;
    },
    cCacheUser() {
      this.clearCacheUser();
      this.whatsapp = "";
      this.step = "identify";
      this.authenticators = [];
      this.$nextTick(() => {
        this.$refs.whatsapp.focus();
      });
    },
  },
  computed: {
    ...mapGetters("auth", ["cacheUser"]),
    isValid() {
      return this.valid || !!this.cacheUser;
    },
  },
  watch: {
    whatsapp() {
      if (this.step !== "identify") {
        this.authenticators = [];
        this.step = "identify";
      }
      this.error = false;
    },
  },
  mounted() {
    if (this.cacheUser) {
      this.ddi = this.cacheUser.ddi;
      this.whatsapp = this.cacheUser.phone;
      this.authenticators = this.cacheUser.authenticators || [];
      this.$nextTick(() => {
        this.step = "login";
      });
    }
  },
  props: {
    redirect: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
