<template>
  <div class="page-wrap text-center">
    <div class="session-form-hold">
      <base-card :loading="pageLoading" class="rounded-lg">
        <v-card-text>
          <img
            v-if="$vuetify.theme.dark"
            class="mb-2"
            height="50"
            src="@/assets/images/logo/logo-light.png"
            alt=""
          />
          <img
            v-else
            class="mb-2"
            height="50"
            src="@/assets/images/logo/logo-dark.png"
            alt=""
          />

          <sign-in-form v-if="!pageLoading" />
        </v-card-text>
      </base-card>
      <v-btn v-if="!inPwa" class="mt-4" dark small text to="/">
        <v-icon left small>mdi-arrow-left</v-icon>
        Voltar para o site
      </v-btn>
    </div>
    <v-btn
      icon
      dark
      style="position: absolute; top: 1rem; right: 1rem"
      @click="toggleDarkMode"
    >
      <v-icon>{{
        $vuetify.theme.dark
          ? "mdi-lightbulb-on-outline"
          : "mdi-moon-waning-crescent"
      }}</v-icon>
    </v-btn>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import SignInForm from "../../../components/global/SignInForm.vue";

export default {
  components: { SignInForm },
  name: "SignUp",
  metaInfo: {
    title: "Acessar conta",
  },
  data() {
    return {
      loading: false,
      pageLoading: true,
      error: false,
    };
  },
  methods: {
    ...mapActions(["changeThemeDarkMode"]),
    toggleDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.changeThemeDarkMode(this.$vuetify.theme.dark);
    },
    redirect() {
      let to = this.$route.query.redirect || "/app";
      this.$router.push(to);
    },
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
  },
  mounted() {
    if (!!this.isAuthenticated) this.redirect();
    else return (this.pageLoading = false);
  },
};
</script>
<style lang="scss" scoped>
.page-wrap {
  background-color: #B01C3F !important;
  display: flex;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}
.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
</style>
